import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Checkbox, RadioGroup, Subtitle, TextField, Title } from "components";
import {
  ConfigOption,
  Option,
} from "features/connection-form/ConnectionForm.types";
import { calculateIfConsumptionIsAllowed, getLast12Months } from "./";
import { useGlobalData } from "../../../../hooks/api";
import { FormLayout, Text } from "../account-state-form/styles";
import { Divider } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import {
  AverageContainer,
  Container,
  ErrorMessage,
  MonthsContainer,
} from "./styles";
import { onlyNumbers } from "utils";

const months = getLast12Months();

const energyBoxAvailability = {
  Monofásico: 30,
  Bifásico: 50,
  Trifásico: 100,
};

type EnergyCalcFormProps = {
  generateRangeValues: (state: string, company: string) => ConfigOption[];
  generateDiscountOptions: (
    state: string,
    company: string,
    averageConsumption: number,
    needManualAnalysis: boolean,
    customer: Record<string, string>
  ) => Option[];
};

export function EnergyCalcForm({
  generateRangeValues,
  generateDiscountOptions,
}: EnergyCalcFormProps) {
  const [discountOptions, setDiscountOptions] = useState<Array<Option>>([]);
  const {
    customer,
    averageConsumption: averageConsumptionGlobal,
    needManualAnalysis,
  } = useGlobalData();
  const {
    watch,
    formState: { errors },
    setValue,
    trigger,
    register,
  } = useFormContext();
  const [searchParams] = useSearchParams();
  const discountFromUrl = searchParams.get("desc") || "";

  const state = watch("ufconsumo");
  const powerCompany = watch("concessionaria");
  const powerSupplier = watch("fornecedora");
  const fieldAverageConsumption = Number(
    watch("consumomedio")?.replace(/\./g, "").replace(",", ".")
  );
  const averageChecked = watch("averageChecked");

  const energyBox: "Monofásico" | "Bifásico" | "Trifásico" = watch("energyBox");

  const monthsValues = months
    .map((month) => parseInt(watch(month)?.replace(".", "")))
    .filter((month) => !!month);

  const monthsFilled = monthsValues.filter((month) => !!month);

  const yearlyAverageConsumption =
    monthsFilled.reduce((acum, monthConsume) => acum + monthConsume, 0) /
    monthsFilled.length;

  const hasPdfFilledYearlyAverageConsumption =
    Number(averageConsumptionGlobal) > 0 || !!customer?.elegibilidade;

  const consumptionLessAvailability =
    Math.ceil(yearlyAverageConsumption - energyBoxAvailability[energyBox]) || 0;

  useEffect(() => {
    if (!customer) return;

    setValue("averageChecked", true);
  }, []);

  useEffect(() => {
    const options = generateDiscountOptions(
      state,
      powerCompany,
      fieldAverageConsumption,
      needManualAnalysis,
      customer
    );

    setDiscountOptions(options);

    if (
      discountFromUrl &&
      options?.some((item) => Number(item.value) === Number(discountFromUrl))
    ) {
      return setValue("desconto_cliente", discountFromUrl);
    }
  }, [fieldAverageConsumption, discountFromUrl, generateDiscountOptions, powerCompany, setValue, state, needManualAnalysis, customer]);

  useEffect(() => {
    if (!discountOptions) {
      return setValue("desconto_cliente", "0");
    }

    if (discountOptions?.length === 1) {
      return setValue("desconto_cliente", discountOptions[0].value.toString());
    }
  }, [discountOptions]);

  useEffect(() => {
    if (
      Number(averageConsumptionGlobal) > 0 ||
      consumptionLessAvailability === 0
    )
      return;
    const consumption = onlyNumbers(consumptionLessAvailability.toString());

    setValue("consumomedio", consumption);
  }, [averageConsumptionGlobal, consumptionLessAvailability]);

  useEffect(() => {
    const rangeValues = generateRangeValues(state, powerCompany);
    const { isAllowed, supplier } = calculateIfConsumptionIsAllowed(
      rangeValues,
      energyBoxAvailability[energyBox],
      fieldAverageConsumption
    );
    if (
      isAllowed &&
      (!powerSupplier ||
        powerSupplier !== supplier ||
        powerSupplier !== customer.fornecedora)
    ) {

      const fornecedora = customer.fornecedora ?? (needManualAnalysis ? "GV" : supplier?.fornecedora);
      const emissaoContrato = supplier?.emissaocontrato;
      const needPassword = supplier?.exige_senhadistribuidora ?? false;

      setValue("fornecedora", fornecedora);
      setValue("emissaocontrato", emissaoContrato);
      setValue("needPassword", needPassword);
    }
  }, [powerCompany, state, energyBox, fieldAverageConsumption, powerSupplier]);

  return (
    <Container>
      <Title>
        Agora precisamos conhecer mais sobre o seu consumo de energia
      </Title>
      <Subtitle>
        Pegue a sua conta de luz e preencha as informações abaixo, se tiver
        alguma dúvida é só consultá-la.
      </Subtitle>

      <AverageContainer>
        <TextField
          label="Consumo médio anual"
          name="consumomedio"
          disabled={averageChecked || !hasPdfFilledYearlyAverageConsumption}
          mask={Number}
          thousandsSeparator={"."}
          radix={","}
          scale={2}
        />
        <Checkbox
          name="averageChecked"
          onChange={() => trigger("consumomedio")}
        />
      </AverageContainer>

      <input type="hidden" {...register("concessionaria")} />
      <input type="hidden" {...register("ufconsumo")} />
      <input type="hidden" {...register("energyBox")} />
      <input type="hidden" {...register("fornecedora")} />

      {!hasPdfFilledYearlyAverageConsumption && <LastYearEnergyConsumption />}
      {!!errors.consumomedio && !hasPdfFilledYearlyAverageConsumption && (
        <ErrorMessage>Campos devem ser preenchidos.</ErrorMessage>
      )}

      {discountOptions?.length > 1 && !discountFromUrl && (
        <>
          <Divider />
          <Subtitle>
            Você tem acesso a opções de desconto disponíveis na adesão.
          </Subtitle>
          <FormLayout>
            <Text>Selecione o desconto desejado:</Text>
            <RadioGroup
              name="desconto_cliente"
              isRow={false}
              options={discountOptions}
            />
          </FormLayout>
        </>
      )}
    </Container>
  );
}

function LastYearEnergyConsumption() {
  return (
    <MonthsContainer>
      {months.map((month) => (
        <TextField
          key={month}
          name={month}
          label={month}
          mask={Number}
          thousandsSeparator={"."}
          radix={"."}
        />
      ))}
    </MonthsContainer>
  );
}
